import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f32a031"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "visit-details" }
const _hoisted_2 = {
  key: 0,
  class: "visit-details__nfz-code"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "visit-details__iframe" }
const _hoisted_5 = {
  key: 0,
  class: "visit-details__loading"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowLeftOutlined = _resolveComponent("ArrowLeftOutlined")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_payment_info = _resolveComponent("payment-info")!
  const _component_CloseOutlined = _resolveComponent("CloseOutlined")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: "visit-details__go-back",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
      }, [
        _createVNode(_component_ArrowLeftOutlined),
        _createTextVNode(" " + _toDisplayString(_ctx.t("VISIT.GO_BACK")), 1 /* TEXT */)
      ]),
      (_ctx.visit?.details?.nfzVisit == true)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("span", null, _toDisplayString(_ctx.t("VISIT.UNIT_PRODUCT_CODE")), 1 /* TEXT */),
            _createVNode(_component_a_select, {
              value: _ctx.nfzProductCode,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.nfzProductCode) = $event)),
              style: {"width":"100%"},
              onChange: _ctx.changeNfzProductCode
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nfzProductCodeOptions, (productCode) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    key: productCode.code
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(`${productCode.code}: ${productCode.description}`), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["value", "onChange"])
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.callLink)
        ? (_openBlock(), _createElementBlock("iframe", {
            key: 1,
            class: "call-frame",
            src: `${_ctx.callLink}`
          }, null, 8 /* PROPS */, _hoisted_3))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_a_spin, {
                size: "large",
                spinning: _ctx.loading,
                tip: _ctx.t('VISIT.LOADING')
              }, null, 8 /* PROPS */, ["spinning", "tip"])
            ]))
          : (_openBlock(), _createElementBlock("iframe", {
              key: 1,
              src: _ctx.url,
              allow: "camera;microphone"
            }, null, 8 /* PROPS */, _hoisted_6))
      ])
    ]),
    _createVNode(_component_payment_info, {
      visit: _ctx.visit,
      modelValue: _ctx.showPaymentModal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showPaymentModal) = $event))
    }, null, 8 /* PROPS */, ["visit", "modelValue"]),
    _createVNode(_component_a_modal, {
      visible: _ctx.showNfzModal,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showNfzModal) = $event)),
      closable: false,
      maskClosable: false,
      onOk: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeInsuraceStatus())),
      onCancel: _ctx.goBack,
      okButtonProps: { disabled: _ctx.isInsuranceOnButtonDisabled },
      okText: _ctx.t('VISIT.ORAL_WRITTEN_DECLARATION'),
      cancelText: _ctx.t('VISIT.GO_BACK')
    }, {
      default: _withCtx(() => [
        (_ctx.nfzStatus?.status == 'UNKNOWN')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.nfzCheckStatusAtteptCount != 2)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                    _createTextVNode(_toDisplayString(_ctx.t("VISIT.LOADING_NFZ_STATUS")) + " ", 1 /* TEXT */),
                    _createVNode(_component_a_spin)
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_8, [
                    _createTextVNode(_toDisplayString(_ctx.t("VISIT.CANNOT_LOAD_STATUS")) + " ", 1 /* TEXT */),
                    _createVNode(_component_CloseOutlined, { style: { color: 'red' } })
                  ]))
            ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
          : (_ctx.nfzStatus?.status == 'UNINSURED')
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.t("VISIT.PATIENT_UNINSURED")), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["visible", "onCancel", "okButtonProps", "okText", "cancelText"])
  ], 64 /* STABLE_FRAGMENT */))
}